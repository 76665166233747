import {
    Button,
    MessageTooltip,
    useStyles,
    useTheme,
} from '@archipro-design/aria';
import { CustomShoppingCart } from '@archipro-design/icons';
import * as S from './ShoppingAvailable.style';
import React from 'react';

interface ShoppingAvailableProps {
    floating?: 'left' | 'right'; //legacy
    inline?: boolean;
}

const ShoppingAvailable = ({ inline, floating }: ShoppingAvailableProps) => {
    const { css } = useStyles({ floating });
    const theme = useTheme();

    const triggerRef = React.useRef<HTMLDivElement>(null);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const showTooltip = () => {
        setTooltipOpen(true);
    };

    const hideTooltip = () => {
        setTooltipOpen(false);
    };

    return (
        <MessageTooltip
            open={tooltipOpen}
            trigger={
                <div
                    ref={triggerRef}
                    className={css(
                        inline
                            ? S.ShoppingAvailableInline
                            : S.ShoppingAvailableContainer
                    )}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                    onMouseDown={hideTooltip}
                >
                    <Button
                        id={'ShoppingAvailable'}
                        icon={<CustomShoppingCart />}
                        size={20}
                        color="white"
                        variables={{
                            iconPadding: 0,
                            buttonPadding: 8,
                            buttonRadius: `50%`,
                            greyButtonBackgroundColorNormal:
                                theme.siteVariables.colors.white['300'],
                            greyButtonBackgroundColorHover:
                                theme.siteVariables.colors.white['250'],
                        }}
                    />
                </div>
            }
            content={{
                children: 'Available to shop',
                size: 16,
                padding: 8,
                color: 'black',
            }}
            position={'above'}
            offset={[0, 8]}
        />
    );
};

export default ShoppingAvailable;
