import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const ShoppingAvailableContainer: StyleRule<{ floating?: string }> = ({
    theme,
    floating,
}) => ({
    width: 'fit-content',
    height: 'fit-content',
    '& svg > path': {
        fill: theme.siteVariables.colors.charcoal[250],
    },

    ...(floating === 'left' && {
        position: 'absolute',
        zIndex: theme.siteVariables.zIndexes.foreground,
        top: pxToRem(8),
        left: pxToRem(8),
        [theme.screen.min.tablet]: {
            top: pxToRem(15),
            left: pxToRem(16),
        },
    }),

    ...(floating === 'right' && {
        position: 'absolute',
        zIndex: theme.siteVariables.zIndexes.foreground,
        top: pxToRem(8),
        right: pxToRem(8),
        [theme.screen.min.tablet]: {
            top: pxToRem(15),
            right: pxToRem(16),
        },
    }),
});

export const ShoppingAvailableInline: StyleRule = ({ theme }) => ({
    width: 'fit-content',
    height: 'fit-content',
    '& svg > path': {
        fill: theme.siteVariables.colors.charcoal[250],
    },
});
